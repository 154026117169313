import { ReduxRouter } from '@lagunovsky/redux-react-router'
import { setViewportFullHeight } from 'helpers/setViewportFullHeight'
import { useDestroyBeforeUnmountComponent } from 'hooks/useDestroyBeforeUnmountComponent'
import { useInitAppConfig } from 'hooks/useInitAppConfig'
import { useInitFirebase } from 'hooks/useInitFirebase'
import { useScrollFix } from 'hooks/useScrollFix'
import { useSnapPixelInitialization } from 'hooks/useSnapPixelInitialization'
import { VideoCoachingSlug } from 'pages/final/VideoCoachingSlug'
import React, { lazy, Suspense } from 'react'
import { routeHistory } from 'route-history'
import { polyfill as smoothScrollPolyfill } from 'smoothscroll-polyfill'
import { Spinner } from './Spinner'

const SubscribeFlow = lazy(() => import('components/SubscribeFlow'))

setViewportFullHeight()
smoothScrollPolyfill()

export const App: React.FC = () => {
  useInitFirebase()
  useInitAppConfig()
  useScrollFix()
  useSnapPixelInitialization()
  useDestroyBeforeUnmountComponent()

  return (
    <ReduxRouter history={routeHistory}>
      <Suspense fallback={<Spinner />}>
        {window.location.href.includes('vc_slug') ? (
          <VideoCoachingSlug />
        ) : (
          <SubscribeFlow />
        )}
      </Suspense>
    </ReduxRouter>
  )
}
