import {
  baseColumnStyles,
  basePageStyles,
  fullViewHeight,
} from 'components/styles'
import { Color, MediaBreakpoint } from 'root-constants'
import styled from 'styled-components'

export const StyledPage = {
  Wrapper: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    width: max(100%, var(--min-screen-width));
    background-color: ${Color.PURPLE};
  `,
  WrapperWhite: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    margin-top: -1px;
    width: max(100%, var(--min-screen-width));
    background-color: ${Color.WHITE};
    justify-content: start;
    align-items: center;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    padding: 40px 0 0;
    justify-content: center;
  `,
  ContentWhite: styled.div`
    ${baseColumnStyles};
    justify-content: start;
    text-align: center;
  `,
  Title: styled.h2`
    align-self: center;
    font-weight: 800;
    font-size: 20px;
    line-height: 48px;
    margin-bottom: 8px;
  `,
  Text: styled.p`
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;
  `,
  Calendar: styled.div`
    width: 100vw;
    height: 100vh;

    .calendly-inline-widget {
      height: 100% !important;
    }
  `,
  Note: styled.div`
    span {
      color: ${Color.PURPLE};
    }
  `,
  Image: styled.img``,
  // Slug styles
  SlugWrapper: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    width: max(100%, var(--min-screen-width));
    background-color: #7879f1;
  `,
  SlugTitle: styled.h2`
    align-self: center;
    font-weight: 800;
    font-size: 20px;
    line-height: 48px;
    margin-bottom: 8px;
    color: ${Color.WHITE};
  `,
  SlugNote: styled.div`
    color: ${Color.WHITE};
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
  `,
  CloseNote: styled.div`
    color: ${Color.WHITE};
    text-align: center;
    margin-top: 20px;
    position: fixed;
    bottom: 20px;
    width: 300px;

    @media (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      padding: 6px 10px;
    }

    strong {
      font-weight: bold;
    }
  `,
  Snow: styled.img`
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
  `,
  XmasDog: styled.img`
    z-index: 2;
  `,
}
