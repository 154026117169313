import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { VC_COHORT_6_MOB, VC_COHORT_6_WEB } from 'pages/final/constants'

// const WEB_APP_SCRIPT_URL =
//   'https://script.google.com/macros/s/AKfycbwu6-oz7zLOdM5VPWOBOembAvCsyA-5lwgT99AMzbP1cz6FyVm56oCjRIHCWXG0btM/exec'

const WEB_APP_SCRIPT_URL_DEV =
  'https://script.google.com/macros/s/AKfycbyIegUOhGlp-9CactGc_mliY3KKCbVy6C8VNbAUIP8v-qUIPv-b18u_CMDqx-qN4S_s/exec'

const HEADERS = {
  createdAt: 'Date (data created)',
  email: 'Email',
  phone: 'Phone number',
  startTime: 'Event data (date, time, timezone)',
  // eslint-disable-next-line
  sex_vc: "Dog's gender",
  // eslint-disable-next-line
  dog_age_vc: "Dog's age",
  // eslint-disable-next-line
  dog_breed_vc: "Dog's breed",
  feed_vc: 'How many times a day do you feed your dog?',
  walk_vc: 'How many times a day does your dog get a walk?',
  stress_vc: 'Does your dog get stressed when home alone?',
  home_along_vc: 'How often do you leave your dog home alone?',
  // eslint-disable-next-line
  activity_vc: "What is your dog's favorite activity?",
  describe_vc: 'How would you describe your dog?',
  points_of_concern_vc: 'Main concerns',
  health_issues_vc: 'Health issues',
  goals_question_vc: 'Main goals',
  cynolog: 'Cynolog',
  answer: 'Description',
  uuid: 'uuid',
}

export const sendInfoToGoogleSheet = async (data, cohort): Promise<void> => {
  const formData = new FormData()
  const env = getCurrentEnv()
  const wayToConnect = [VC_COHORT_6_WEB, VC_COHORT_6_MOB].includes(cohort)
    ? 'Woofz chat'
    : 'old flow'
  formData.append('env', env)
  formData.append('cohort', cohort)
  formData.append('Way to connect', wayToConnect)

  Object.keys(data).forEach((item) => {
    if (HEADERS[item]) {
      formData.append(HEADERS[item], data[item])
    }
  })

  try {
    await fetch(WEB_APP_SCRIPT_URL_DEV, {
      method: 'POST',
      mode: 'no-cors',
      body: formData,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  } catch (e) {
    console.error('Errors:', e)
  }
}
