import { IAppState } from 'models/store.model'
import { DEFAULT_CURRENCY, TimeInterval } from 'root-constants'
import { ISubscription } from 'models/subscriptions.model'
import { TPaymentOptions } from '../types'

export const selectCancelOffer = (state: IAppState): ISubscription | null =>
  state.payment.cancelOffer || null
export const select3DSecureIframeUrl = (state: IAppState): string =>
  state.payment.threeDSecureIframeURL
export const selectPaymentClientSecret = (state: IAppState): string =>
  state.payment.paymentClientSecret
export const selectSubscriptionId = (state: IAppState): string =>
  state.payment.subscriptionId
export const selectIsPaymentFlowsShown = (state: IAppState): boolean =>
  state.payment.isPaymentFlowsShown
export const selectIsStoreButtonsShown = (state: IAppState): boolean =>
  state.payment.isStoreButtonsShown
export const selectPaymentMethod = (state: IAppState): string =>
  state.payment.paymentMethod

// TODO selectSubscription for second version api, after replace
export const selectSubscription = (state: IAppState): ISubscription | null =>
  state.payment.selectedSubscription || null

export const selectPlanId = (state: IAppState): number =>
  state.payment.selectedSubscription?.planId || 0

export const selectSubscriptionPeriodName = (
  state: IAppState,
): TimeInterval | null =>
  state.payment.selectedSubscription?.mainPrices.periodName || null

export const selectSubscriptionPeriodQuantity = (
  state: IAppState,
): number | null =>
  state.payment.selectedSubscription?.mainPrices.periodQuantity || null

export const selectSubscriptionFullPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.fullPrice || 0

export const selectSubscriptionOldFullPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.oldPrices.fullPrice || 0

export const selectSubscriptionTrialFullPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.fullPrice || 0

export const selectSubscriptionTrialOldFullPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.oldPrices.fullPrice || 0

export const selectSubscriptionDayPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.daily || 0

export const selectTrialDurationDays = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.durationDays || 0

export const selectCurrency = (state: IAppState): string =>
  state.payment.selectedSubscription?.currency || DEFAULT_CURRENCY

export const selectIntroId = (state: IAppState): string | null =>
  state.payment.selectedSubscription?.introLookupKey || null

export const selectRecurringId = (state: IAppState): string | null =>
  state.payment.selectedSubscription?.recurringLookupKey || null

export const selectPayPalPlanId = (state: IAppState): string =>
  state.payment.selectedSubscription?.paypalPlanId || ''

export const selectProductPrice = (state: IAppState): string =>
  state.payment.selectedSubscription?.productPrice || ''

export const selectPaymentRequestOptions = (
  state: IAppState,
): TPaymentOptions | null => state.payment.paymentOptions || null
