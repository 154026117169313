import { IRequestOptions, IResponseResult } from 'models/api.model'
import { TAnswers } from 'models/common.model'
import {
  IBindUserResponseRaw,
  IGetUserStatusResponseRaw,
} from 'models/user.model'
import { ENV_ADMIN_MAP, FLOW, RequestMethod } from 'root-constants'
import { ApiService } from 'services/api.service'
import { removeStorageData } from 'services/calendly.service'
import { getCurrentEnv } from 'helpers/getCurrentEnv'

export class UserApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  signUpUser(payload: {
    token: string
    uuid: string
    appName: string
  }): Promise<IResponseResult<IBindUserResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        token: payload.token,
      },
    }

    return this.api.makeRequest<IBindUserResponseRaw>(
      `v2/leads/${payload.uuid}/${payload.appName}/signup`,
      options,
    )
  }

  bindUser(payload: {
    token: string
    uuid: string
    appName: string
  }): Promise<IResponseResult<IBindUserResponseRaw>> {
    const { appName, token, uuid } = payload
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        app_name: appName,
        oauth_token: token,
        uuid,
      },
    }

    return this.api.makeRequest<IBindUserResponseRaw>('/v1/flow/bind', options)
  }

  createLead(
    cohort: string,
    appId: string,
    locale: string,
    utmTags: Record<string, string | null>,
    appName: string,
    giaFlow: FLOW,
  ): Promise<IResponseResult<string>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        cohort,
        app_id: appId,
        locale,
        app_name: appName,
        utm_tags: utmTags,
        gia_flow: giaFlow,
      },
    }

    // TODO remove after backend implementation
    removeStorageData()

    return this.api.makeRequest<string>('v2/leads', options)
  }

  saveFacebookParams({
    fbc,
    fbp,
    uuid,
    appName,
  }: {
    uuid: string
    fbc: string
    fbp: string
    appName: string
  }): Promise<IResponseResult<string>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        fbc,
        fbp,
      },
    }

    return this.api.makeRequest<IResponseResult<string>>(
      `v2/leads/${uuid}/${appName}/facebook`,
      options,
    )
  }

  saveUserEmail({
    email,
    uuid,
    appName,
  }: {
    uuid: string
    email: string
    appName: string
  }): Promise<IResponseResult<string>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        email,
      },
    }

    return this.api.makeRequest<IResponseResult<string>>(
      `v2/leads/${uuid}/${appName}/email`,
      options,
    )
  }

  sendUserInfo(payload: {
    uuid: string
    email: string
    answers: TAnswers
    fbp: string
    fbc: string
    awcParam: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        uuid: payload.uuid,
        email: payload.email,
        onboarding: payload.answers,
        fbp: payload.fbp,
        fbc: payload.fbc,
      },
    }

    return this.api.makeRequest<never>(
      `v1/flow/userinfo${payload.awcParam ? `?awc=${payload.awcParam}` : ''}`,
      options,
    )
  }

  getUserStatus(
    uuid: string,
    appName: string,
  ): Promise<IResponseResult<IGetUserStatusResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<IGetUserStatusResponseRaw>(
      `v2/leads/${uuid}/${appName}`,
      options,
    )
  }

  saveUserTTCLID({
    ttclid,
    uuid,
  }: {
    ttclid: string
    uuid: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      params: { ttclid },
    }

    return this.api.makeRequest(`v2/leads/${uuid}/tiktok`, options)
  }

  createUserProfile({
    id,
    timestamp,
    data,
    token,
  }: {
    id: string
    timestamp: number
    data: any
    token: string
  }): Promise<Response> {
    const options = {
      method: RequestMethod.POST,
      body: [
        {
          id,
          timestamp,
          ...data,
        },
      ],
    }
    const requestBody = options.body ? JSON.stringify(options.body) : undefined
    const currentEnv = getCurrentEnv()
    const url = ENV_ADMIN_MAP[currentEnv]

    return fetch(`https://${url}/api/v1/dog/profiles`, {
      method: options.method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: requestBody,
      credentials: 'include',
    })
  }

  sendUserInfoToApp({
    uuid,
    token,
  }: {
    uuid: string
    token: string
  }): Promise<Response> {
    const options = {
      method: RequestMethod.POST,
      body: {
        uuid,
      },
    }
    const requestBody = options.body ? JSON.stringify(options.body) : undefined
    const currentEnv = getCurrentEnv()
    const url = ENV_ADMIN_MAP[currentEnv]

    return fetch(`https://${url}/api/v1/users/meta`, {
      method: options.method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: requestBody,
      credentials: 'include',
    })
  }

  getUserProfileInfo(token: string): Promise<IResponseResult<Response>> {
    const currentEnv = getCurrentEnv()
    const url = ENV_ADMIN_MAP[currentEnv]
    const options = {
      method: RequestMethod.GET,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      credentials: 'include',
    }

    return this.api.makeCustomRequest<Response>(
      `${url}/api/v1/dog/profile`,
      options,
    )
  }

  sendDogTrainerInfo({
    token,
    email,
    trainerEmail,
    userName,
  }: {
    token: string
    email: string
    trainerEmail: string
    userName: string
  }): Promise<IResponseResult<never>> {
    const currentEnv = getCurrentEnv()
    const url = ENV_ADMIN_MAP[currentEnv]
    const options = {
      method: RequestMethod.POST,
      body: {
        trainer_email: trainerEmail,
        user_name: userName,
        user_email: email,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    return this.api.makeCustomRequest(`${url}/api/v1/dogtrainer/chat`, options)
  }

  confirmPurchase(token: string): Promise<IResponseResult<never>> {
    const currentEnv = getCurrentEnv()
    const url = ENV_ADMIN_MAP[currentEnv]
    const options = {
      method: RequestMethod.POST,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: {},
    }

    return this.api.makeCustomRequest(
      `${url}/api/v1/dogtrainer/chat/purchased`,
      options,
    )
  }
}
