import { IStep } from 'models/variant.model'

const map = {
  ageGroupName: 'dog_age_vc_2',
  formattedBehaviorIssues: 'behavior_vc',
  gender: 'sex_vc',
  isShelterDog: 'shelter_vc',
  numberOfWalksPerDayName: 'walk_vc',
  selectedBreedName: 'dog_breed_vc',
  name: 'name',
}

export const mapPageAnswers = (
  rawAnswers: Record<string, string>,
): Record<string, string> => {
  const answers = {}
  // eslint-disable-next-line guard-for-in
  for (const key in rawAnswers) {
    if (map[key]) {
      answers[map[key]] = rawAnswers[key]
    }
  }

  return answers
}

export const filterPagesAccordingUserAnswers = (
  steps: IStep[],
  answers: Record<string, string>,
): IStep[] => steps.filter((item) => !answers[item.id])
