import { TimeInterval } from 'root-constants'

export function createUpdatedProductName({
  periodName,
  periodQuantity,
  price,
  introPrice,
  introQuantity,
  currency,
}: {
  periodName: TimeInterval | null
  periodQuantity: number | null
  price: number
  introPrice: number
  introQuantity: number
  currency: string
}): string {
  const formattedIntroQuantity = () => {
    if (!introQuantity) {
      return '0_days'
    }

    if (introQuantity === 28 || introQuantity === 84) {
      return `${introQuantity / 28}_mo`
    }

    return introQuantity < 30
      ? `${introQuantity}_days`
      : `${introQuantity / 30}_mo`
  }

  const formattedPeriodName =
    periodName === TimeInterval.MONTH ? 'mo' : periodName
  const formattedPeriodQuantity = () => {
    if (periodQuantity === 28 || periodQuantity === 84) {
      return `${periodQuantity / 28}_mo`
    }
    return `${periodQuantity}_${formattedPeriodName}`
  }

  return `woofz_${currency}_${formattedPeriodQuantity()}_${price}_intro_${formattedIntroQuantity()}_${introPrice}`
}
