import firebase from 'firebase/app'
import { getWebFunnelsConfig } from 'helpers/getWebFunnelsConfig'
import { shouldSendEventsByRemoteConfig } from 'helpers/shouldSendEventsByRemoteConfig'
import { ICalendlyInfo, IDogBreed, TAnswers } from 'models/common.model'
import { IConfig, IRemoteConfigFile } from 'models/config.model'
import { IStep, IVariant } from 'models/variant.model'
import { AnyAction } from 'redux'
import {
  APP_NAME,
  DEFAULT_WEB_FUNNELS_CONFIG_FILE,
  vcInitialStatus,
} from 'root-constants'
import {
  RESET_ANSWERS,
  RESET_ERROR,
  SET_ANSWERS,
  SET_APP_NAME,
  SET_BREEDS,
  SET_CALENDLY_INFO,
  SET_CONFIG,
  SET_ERROR,
  SET_FIREBASE_REMOTE_CONFIG,
  SET_FIREBASE_TOKEN,
  SET_IS_CANCEL_OFFER_APPLIED,
  SET_IS_CANCEL_OFFER_NOT_APPLIED,
  SET_IS_LOGGER_INITIALIZED,
  SET_IS_PAID_TRIAL_MODAL_SHOWN,
  SET_IS_UPSELL_PAID,
  SET_LANGUAGE,
  SET_ONBOARDING_ANSWERS,
  SET_OPTIMIZE_VARIANT_ID,
  SET_PAYMENT_CONFIG,
  SET_SUBSCRIPTION_PLANS,
  SET_VARIANT,
  SET_VC_STEPS,
  SET_WEBAPP_REDIRECT_FLAG,
  START_FETCHING,
  STOP_FETCHING,
} from 'root-redux/actions/common'
import { IPaymentConfig } from 'models/payment.model'
import { ISubscription } from 'models/subscriptions.model'
import { SET_PLANS_WAS_LOADED } from 'modules/payment/redux/actions'
import { IVcUserStatus } from 'models/user.model'

const initState: ICommonState = {
  isFetching: false,
  actionList: [],
  error: null,
  currentVariant: null,
  config: null,
  answers: null,
  answersFromServer: null,
  vcSteps: [],
  vcUserStatus: vcInitialStatus,
  optimizeVariantId: 'no_test',
  optimizeSegmentName: 'no_test',
  isCancelOfferApplied: false,
  isCancelOfferNotApplied: false,
  language: '',
  firebaseRemoteConfig: null,
  shouldSendEventsByRemoteConfig: false,
  firebaseConfigFile: DEFAULT_WEB_FUNNELS_CONFIG_FILE,
  isFirebaseConfigLoaded: false,
  isPaidTrialModalShown: true,
  isUpsellPaid: false,
  isLoggerInitialized: false,
  calendly: {},
  planNumber: 4001,
  appName: APP_NAME,
  paymentConfig: null,
  subscriptions: [],
  subscriptionsPlansWasLoaded: false,
  isWebAppFunnel: false,
  breeds: {},
  token: null,
}

export interface ICommonState {
  isFetching: boolean
  actionList: string[]
  error: any
  currentVariant: IVariant | null
  config: IConfig | null
  answers: TAnswers | null
  answersFromServer: Record<string, string> | null
  vcSteps: IStep[]
  vcUserStatus: IVcUserStatus
  optimizeVariantId: string
  optimizeSegmentName: string
  isCancelOfferApplied: boolean
  isCancelOfferNotApplied: boolean
  language: string
  firebaseRemoteConfig: firebase.remoteConfig.RemoteConfig | null
  shouldSendEventsByRemoteConfig: boolean
  firebaseConfigFile: IRemoteConfigFile
  isFirebaseConfigLoaded: boolean
  isPaidTrialModalShown: boolean
  isUpsellPaid: boolean
  isLoggerInitialized: boolean
  calendly: ICalendlyInfo
  planNumber: number
  appName: string
  paymentConfig: IPaymentConfig | null
  subscriptions: ISubscription[]
  subscriptionsPlansWasLoaded: boolean
  isWebAppFunnel: boolean
  breeds: Record<string, IDogBreed[]>
  token: string | null
}

export function commonReducer(
  state: ICommonState = initState,
  { type, payload = null }: AnyAction,
): ICommonState {
  switch (type) {
    case START_FETCHING: {
      return {
        ...state,
        isFetching: true,
        actionList: state.actionList.concat(payload),
      }
    }
    case STOP_FETCHING: {
      return {
        ...state,
        isFetching: !!payload.length,
        actionList: payload,
      }
    }
    case SET_ERROR: {
      return {
        ...state,
        error: payload,
      }
    }
    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      }
    }
    case SET_VARIANT: {
      return {
        ...state,
        currentVariant: {
          ...payload,
        },
      }
    }
    case SET_CONFIG: {
      return {
        ...state,
        config: payload,
      }
    }
    case SET_ANSWERS: {
      return {
        ...state,
        answers: { ...state.answers, ...payload },
      }
    }
    case SET_VC_STEPS: {
      return {
        ...state,
        vcSteps: [...payload],
      }
    }
    case SET_FIREBASE_TOKEN: {
      return {
        ...state,
        token: payload,
      }
    }
    case SET_ONBOARDING_ANSWERS: {
      return {
        ...state,
        answers: payload,
      }
    }
    case RESET_ANSWERS: {
      return {
        ...state,
        answers: null,
      }
    }
    case SET_IS_CANCEL_OFFER_APPLIED: {
      return {
        ...state,
        isCancelOfferApplied: payload,
      }
    }
    case SET_IS_CANCEL_OFFER_NOT_APPLIED: {
      return {
        ...state,
        isCancelOfferNotApplied: payload,
      }
    }
    case SET_OPTIMIZE_VARIANT_ID: {
      return {
        ...state,
        optimizeVariantId: payload,
        optimizeSegmentName:
          state.currentVariant?.optimizeMap?.[payload] || 'no_name',
      }
    }
    case SET_LANGUAGE: {
      return {
        ...state,
        language: payload,
      }
    }
    case SET_IS_LOGGER_INITIALIZED: {
      return {
        ...state,
        isLoggerInitialized: payload,
      }
    }
    case SET_FIREBASE_REMOTE_CONFIG: {
      return {
        ...state,
        isFirebaseConfigLoaded: true,
        firebaseRemoteConfig: payload,
        shouldSendEventsByRemoteConfig: shouldSendEventsByRemoteConfig({
          remoteConfig: payload,
          cohort: state.currentVariant?.cohort || '',
          currentLanguage: state.language,
        }),
        firebaseConfigFile: getWebFunnelsConfig(payload),
      }
    }
    case SET_IS_PAID_TRIAL_MODAL_SHOWN: {
      return {
        ...state,
        isPaidTrialModalShown: payload,
      }
    }
    case SET_IS_UPSELL_PAID: {
      return {
        ...state,
        isUpsellPaid: payload,
      }
    }
    case SET_CALENDLY_INFO: {
      return {
        ...state,
        calendly: payload,
      }
    }
    case SET_APP_NAME: {
      return {
        ...state,
        appName: payload,
      }
    }
    case SET_PAYMENT_CONFIG: {
      return {
        ...state,
        paymentConfig: payload,
      }
    }
    case SET_SUBSCRIPTION_PLANS: {
      return {
        ...state,
        subscriptions: payload,
        subscriptionsPlansWasLoaded: true,
      }
    }
    case SET_PLANS_WAS_LOADED: {
      return {
        ...state,
        subscriptionsPlansWasLoaded: payload,
      }
    }
    case SET_WEBAPP_REDIRECT_FLAG: {
      return {
        ...state,
        isWebAppFunnel: payload,
      }
    }
    case SET_BREEDS: {
      return {
        ...state,
        breeds: payload,
      }
    }
    default:
      return state
  }
}
