import { Color, MediaBreakpoint } from 'root-constants'
import { createGlobalStyle, css } from 'styled-components'

export const baseMediaStyles = css`
  width: 288px;

  @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
    width: 343px;
  }
`

export const baseMediaStylesRT = css`
  width: 288px;

  @media (min-width: ${MediaBreakpoint.SALES_SCREEN_MIN_PHONE}px) {
    width: 343px;
  }
`

export const baseMediaStylesRfnd = css`
  width: 328px;

  @media (min-width: ${MediaBreakpoint.SALES_SCREEN_MIN_PHONE}px) {
    width: 398px;
  }
`

export const baseMediaStylesVC = css`
  width: 328px;

  @media (min-width: ${MediaBreakpoint.PHONE_WITH_390_MIN}px) {
    width: 343px;
  }
`
export const LightBrownThemeGlobalStyle = createGlobalStyle`
   body {
     background-color: ${Color.BEIGE};
     background-image: none;
   }
`

export const basePageStyles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`
export const baseColumnStyles = css`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${baseMediaStyles};
`

export const baseColumnStylesVC = css`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${baseMediaStylesVC};
`

export const baseColumnStylesRT = css`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${baseMediaStylesRT};
`

export const baseColumnStylesRfnd = css`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${baseMediaStylesRfnd};
`

export const baseTitleStyles = css`
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
`

export const baseFashionTitleStyles = css`
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  text-align: center;
  color: ${Color.BLACK};
  font-family: 'Noto Serif', serif;
`

export const baseFashionTextStyles = css`
  color: ${Color.GREY_TEXT};
  font-family: 'Poppins', sans-serif;
`

export const baseTextStyles = css`
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: rgba(${Color.PRIMARY_TEXT_RGB}, 0.8);
  text-align: center;
`

export const baseTextPTStyles = css`
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
`

export const desktopMediaQuery = `(min-width: ${MediaBreakpoint.MAX_PHONE}px)`
export const salesDesktopMediaQuery = `(min-width: ${MediaBreakpoint.SALES_SCREEN_MAX_PHONE}px)`

export const stickyButtonWrapper = css`
  ${baseMediaStyles};
  position: fixed;
  z-index: 1;
  bottom: 55px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const fullViewHeight = css`
  height: var(--full-height, 100vh);

  @supports (height: 100dvh) {
    height: 100dvh;
  }
`

export const fullViewMinHeight = css`
  min-height: var(--full-height, 100vh);

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }
`
