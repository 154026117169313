import { TProductId, TProductName } from 'models/variant.model'
import { TimeInterval } from 'root-constants'
import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'
import { getIsNotSendPurchaseEvents } from 'helpers/getIsNotSendPurchaseEvents'
import { IStripeAccount } from 'models/common.model'
import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { PaymentMethod } from '../constants'

export const logSuccessfulPayment = ({
  productId,
  productName,
  price,
  currency = 'USD',
  trialPrice,
  trialPeriodDays,
  subscriptionId,
  discountApplied,
  uuid,
  periodName,
  periodQuantity,
  optimizeExperimentId,
  optimizeVariantId,
  optimizeSegmentName,
  paymentMethod,
  email,
  cohort,
  screenName,
  isUpsell,
  productPrice,
  stripeAccount,
  lang,
  webapp,
}: {
  productId: TProductId
  productName: TProductName
  price: number
  currency?: string
  trialPrice?: number
  trialPeriodDays?: number
  subscriptionId: string
  discountApplied?: string
  uuid: string
  periodName: TimeInterval | null
  periodQuantity: number | null
  optimizeExperimentId: string
  optimizeVariantId: string
  optimizeSegmentName: string
  paymentMethod?: PaymentMethod
  email: string
  cohort: string
  screenName?: string
  isUpsell?: boolean
  productPrice: string
  stripeAccount?: IStripeAccount
  webapp: boolean
  lang: string
}): void => {
  const isNotSendPurchaseEvents = getIsNotSendPurchaseEvents() || isUpsell
  const currentEnv = getCurrentEnv()

  if (isUpsell) {
    window.pintrk &&
      window.pintrk('track', 'Custom', {
        product_price: trialPrice || price,
        currency,
        order_id: subscriptionId,
      })
  }

  if (trialPeriodDays && !isUpsell) {
    window.fbq(
      'track',
      'StartTrial',
      {
        value: trialPrice,
        currency,
        subscription_id: subscriptionId,
        // This field and similar ones below have been added to avoid FB pixel issue. https://github.com/facebook/facebook-nodejs-business-sdk/issues/164
        subscription_sid: subscriptionId,
      },
      { eventID: uuid },
    )
  }
  if (!isNotSendPurchaseEvents) {
    window.fbq(
      'track',
      'Subscribe',
      {
        value: trialPrice || price,
        currency,
        subscription_id: subscriptionId,
        subscription_sid: subscriptionId,
      },
      { eventID: uuid },
    )
    window.fbq(
      'track',
      'Purchase',
      {
        value: trialPrice || price,
        currency,
        subscription_id: subscriptionId,
        subscription_sid: subscriptionId,
      },
      { eventID: uuid },
    )
    window.ttq.identify({ email })
    window.ttq.track('CompletePayment', {
      subscription_id: subscriptionId,
      content_id: uuid,
      currency,
      value: trialPrice || price,
      event_id: uuid,
      paymentMethod,
    })
    window.snaptr('track', 'PURCHASE', {
      price: trialPrice || price,
      currency,
      transaction_id: subscriptionId,
      user_email: email,
    })
    window.pintrk &&
      window.pintrk('track', 'Checkout', {
        product_price: trialPrice || price,
        currency,
        order_id: subscriptionId,
      })

    if (window.uetq && currentEnv === 'prod') {
      window.uetq = window.uetq || []
      window.uetq.push('event', 'purchase', {
        revenue_value: trialPrice || price,
        currency,
      })
    }
  }

  eventLogger.logPurchaseCompleted({
    productId,
    productName,
    email,
    cohort,
    priceDetails: {
      price,
      trial: !!trialPrice,
      currency,
    },
    optimizeExperimentId,
    optimizeVariantId,
    optimizeSegmentName,
    paymentMethod,
    transactionId: subscriptionId,
    screenName,
    ...(discountApplied && { discountApplied }),
    isUpsell,
    productPrice,
    stripeAccount,
    lang,
    webapp,
  })

  googleAnalyticsLogger.logPurchaseCompleted({
    subscriptionId,
    price: trialPrice || price,
    periodName,
    periodQuantity,
  })
}
