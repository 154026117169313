/* eslint-disable max-lines */
import { TAnswers } from 'models/common.model'
import { IVcUserStatus } from 'models/user.model'

export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
}

export const enum SubscriptionDurationDays {
  ONE_MONTH = 30,
  THREE_MONTHS = 90,
  SIX_MONTHS = 180,
}

export const PeriodCountByDays = {
  [SubscriptionDurationDays.ONE_MONTH]: 1,
  [SubscriptionDurationDays.THREE_MONTHS]: 3,
  [SubscriptionDurationDays.SIX_MONTHS]: 6,
}

export const enum PLAN_TYPES {
  PURCHASE = 'purchase',
  UPSELL = 'upsell',
  ONE_TIME = 'one_time',
  SECONDARY = 'secondary',
}

export const enum PLAN_TAGS {
  CANCEL = 'cancel',
  NO_TAX = 'no_tax',
  UPSELL = 'upsell',
  VC = 'vc',
  WW = 'ww',
  US = 'us',
}

export const enum MediaBreakpoint {
  MIN_PHONE = 321,
  MEDIUM_PHONE = 360,
  MAX_PHONE = 415,
  SALES_SCREEN_MAX_PHONE = 431,
  SALES_SCREEN_MIN_PHONE = 430,
  TABLET = 520,
  PHONE_WITH_390_MIN = 390,
  PHONE_WITH_390_MAX = 389,
}

export const enum StripeFieldName {
  NAME = 'name',
  NUMBER = 'cardNumber',
  EXPIRY = 'cardExpiry',
  CVC = 'cardCvc',
  EMAIL = 'email',
}

export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const enum LoginMethod {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export const enum LoginMethodRaw {
  EMAIL = 'password',
  GOOGLE = 'google.com',
  FACEBOOK = 'facebook.com',
  APPLE = 'apple.com',
}

export const LOGIN_METHOD_RAW_MAP = {
  [LoginMethodRaw.EMAIL]: LoginMethod.EMAIL,
  [LoginMethodRaw.GOOGLE]: LoginMethod.GOOGLE,
  [LoginMethodRaw.FACEBOOK]: LoginMethod.FACEBOOK,
  [LoginMethodRaw.APPLE]: LoginMethod.APPLE,
}

export const enum FirebaseSignUpErrorCode {
  EMAIL_EXISTS = 'auth/email-already-in-use',
}

export const AMPLITUDE_API_KEY = '7c5616a3d04052c3bbe80896ac80c6d0'

export const enum ValidationText {
  EMAIL_EXISTS = 'account.accountAlreadyExist',
  INVALID_EMAIL = 'onboarding.email.validation',
  INVALID_EMAIL_VARIANT_2 = 'Please, check email address. It looks like there might be a typo',
  EMPTY_EMAIL_ERROR = 'Please, provide your email that we can send you the training plan',
  SHORT_PASSWORD = 'account.shortPassword',
  WRONG_CONFIRMATION_PASSWORD = 'account.notEqualPassword',
  WRONG_PASSWORD = 'The password you entered is incorrect',
  WRONG_EMAIL = 'The email you entered is incorrect',
  INVALID_TEXT = `Your input doesn't look valid. Please, check`,
}

export const enum Color {
  ERROR_RGB = '255, 97, 97',
  PRIMARY_TEXT_RGB = '74, 53, 50',
  MEDIUM_GREEN_RGB = '53, 180, 63',
  ERROR = '#FF6161',
  BG_MAIN = '#F4E8DE',
  BEIGE = '#FFF5E9',
  BEIGE_LIGHT = '#FFF6EF',
  BEIGE_ALTERNATIVE = '#F7F6F1',
  WHITE = '#FFFFFF',
  BROWNISH_GRAY = '#B0A099',
  PRIMARY_TEXT = '#4A3532',
  PRIMARY_TEXT_SHADOW = '#4A3532CC',
  COLD_GREEN = '#4bab77',
  MEDIUM_GREEN = '#35B43F',
  LIGHT_MOSS_GREEN = '#94CE8E',
  YELLOW_ORANGE = '#F8B900',
  YELLOW_LIGHT = '#FFF192',
  POWDERY_PINK = '#EA8889',
  PURPLE = '#7879F1',
  VIOLET = '#5B5DE4',
  BLACK = '#000',
  LILAC = '#6136C6',
  DARK_VIOLET = '#4A23A5',
  GREY_FIELD_INPUT = '#FFFAF7',
  DARK_PURPLE = '#854eb3',
  GREY_TEXT = '#848484',
  VIOLET_ALTERNATIVE = '#F1F0FF',
  VIOLET_SECONDARY = '#978FFF',
}

export const enum TimeInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const enum EventLoggerInstanceName {
  GIA = 'gia-amplitude',
  AMPLITUDE = 'amplitude',
  USER_FLOW_TRACKER = 'user-flow-tracker',
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum AppWithInAppBrowser {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export const enum PagePath {
  LOCALHOST = 'localhost',
  TUTORIAL = 'tutorial',
  SUBSCRIPTIONS = 'subscriptions',
}

export const enum AnswerKey {
  DOG_BREED = 'dog_breed',
  GOALS_QUESTION = 'goals_question',
  POINTS_OF_CONCERNS = 'points_of_concern',
  COMMANDS = 'commands',
  TIME = 'time',
  HEALTH_ISSUES = 'health_issues',
  AGE = 'dog_age',
  SEX = 'sex',
  DOG_NAME = 'dog_name',
  SHELTER = 'shelter',
  TRAINING_DIFFICULTIES = 'training_difficulties',
  BEHAVIOR = 'behavior',
  DOG_BODY_CONDITION_TITLE = 'dog_body_condition_title',
  DOG_BODY_CONDITION_PERCENT = 'dog_body_condition_percent',
  DOG_ENERGY = 'dog_energy',
  DOG_NEUTERED = 'dog_neutered',
  TALL_FASHION_NUMBER_IN = 'tall_fashion_number_in',
  TALL_FASHION_NUMBER_FT = 'tall_fashion_number_ft',
  TALL_FASHION_NUMBER_CM = 'tall_fashion_number_cm',
  TALL_FASHION_UNIT = 'tall_fashion_unit',
  WEIGHT_FASHION_NUMBER = 'weight_fashion_number',
  WEIGHT_FASHION_UNIT = 'weight_fashion_unit',
  STYLE_GOAL_FASHION = 'style_goal_fashion',
  BODY_SHAPE_FASHION = 'body_shape_fashion',
  REASON_FASHION = 'reason_fashion',
  LIFESTYLE_FASHION = 'lifestyle_fashion',
  AGE_FASHION = 'age_fashion',
  VISITING_PLACES = 'visiting_places',
  DOG_IGNORED = 'dog_ignored',
  BOND_KNOWLEDGE = 'bond_knowledge',
  DOG_IMPROVE = 'dog_improve',
  BREED_KEY = 'breed_key',
  OTHER_DOGS_REACTION = 'other_dogs_reaction',
  BEHAVIOR_RT = 'behavior_rt',
  GOALS_RT = 'goals_rt',
}

export const DEFAULT_COHORT = 'woofz13'
export const VC_COHORT = 'vc_cohort'
export const WEBAPP_COHORT = 'woofz_wa1'
export const FSHN_COHORT = 'fshn'
export const APP_NAME = 'woofz'
export const FASHION_APP_NAME = 'stylio'
export const CENTS_IN_DOLLAR = 100
export const INITIAL_PRICE = 0
export const CUSTOM_TOKEN_LOCAL_STORAGE_KEY = 'CUSTOM_TOKEN'
export const DEFAULT_CURRENCY = 'usd'
export const LANG_QUERY_PARAM = 'lang'
export const INITIAL_LANGUAGE = 'en'

export const DAYS_IN_PERIOD: { [key: string]: number } = {
  [TimeInterval.WEEK]: 7,
  [TimeInterval.MONTH]: 30,
}

export const REMOVED_COHORTS = [
  'woofz1',
  'woofz2',
  'woofz3',
  'woofz4',
  'woofz5',
  'woofz6',
  'woofz7',
]

export const DIFFICULTIES_OPTIONS_VALUES = {
  RIGHT_TIME: 'rightTime',
  NO_EXPERT_CHECK: 'noExpertCheck',
  DOG_LOSE_MOTIVATION: 'dogLoseMotivation',
  INSTRUCTIONS_COMPLICATED: 'instructionsComplicated',
  NOT_SEE_PROGRESS: 'notSeeProgress',
  NOT_HAVE_ANY: 'notHaveAny',
}

export const COMMANDS_OPTIONS_VALUES = {
  MARKER: 'marker',
  LOOK: 'look',
  NAME: 'name',
  NO: 'no',
  FOOD_LURING: 'foodLuring',
  STAND: 'stand',
  LEAVE: 'leaveIt',
  OFF: 'off',
  RELEASE_CUE: 'releaseCue',
  SPIN: 'spin',
  HIDE: 'hide',
  PLACE: 'place',
  BUNNY: 'bunny',
  BOW: 'bow',
  TELL_SECRET: 'tellASecret',
  CATCH_FRISBEE: 'catchAFrisbee',
  JUMP_THROGH_ARM: 'jumpThroughTheArmCircle',
  DEAD: 'dead',
  WIPE: 'wipeYourPaws',
  SLIPPERS: 'getMySlippers',
  WALK: 'walkBetweenYourLegs',
  FETCH: 'fetch',
  TOYS: 'toysInTheBox',
  BALANCE: 'balance',
  SHAME: 'shame',
  JUMP_ON_BACK: 'jumpOnTheBack',
  JUMP_INTO_ARMS: 'jumpIntoArms',
  JUMP_OFF_AND_TURN: 'jumpOffAndTurn',
  STAY: 'stay',
  DROP: 'dropIt',
  GIVE: 'give',
  ROLL: 'rollOver',
  DOWN: 'down',
  COME: 'come',
  PLAY: 'playDead',
  SIT: 'sit',
  BEG: 'beg',
  NULL: 'null',
  SNAKE: 'snake',
  TOUCH: 'touch',
  KISS: 'kiss',
  FIVE: 'highFive',
  HUG: 'hug',
  TAKE: 'takeIt',
  NOSE: 'nose',
}

export const POTTY_OPTIONS = {
  WITHOUT_SCHEDULE: 'withoutSchedule',
  NIGHT: 'night',
  LEFT_ALONE: 'leftAlone',
  TRAINED: 'trained',
}

export const BEHAVIOR_OPTIONS = {
  BARKING: 'barking',
  LEASH_POOLING: 'leashPooling',
  SEPARATION_ANXIETY: 'separationAnxiety',
  CHEWING: 'chewing',
  AGGRESSION: 'aggression',
  BITING: 'biting',
  HOUSE_SOILING: 'houseSoiling',
  DIGGING: 'digging',
  FOOD_STEALING: 'foodStealing',
  CONTROL: 'control',
  POTTING_WITHOUT_SCHEDULE: 'pottingWithoutSchedule',
}

export const SEX_OPTIONS = {
  GIRL: 'girl',
  BOY: 'boy',
}

export const CONCERN_OPTIONS_VALUES = {
  CHEWING: 'chewing',
  BARKING: 'barking',
  DIGGING: 'digging',
  FOOD_STEALING: 'foodStealing',
  BITING: 'biting',
  PULING_ON_LEASH: 'pulingLeash',
  SEPARATION_ANXIETY: 'separationAnxiety',
  POTTY_TRAINING: 'pottyTraining',
  SOCIALIZATION: 'socialization',
  BASIC_DISOBEDIENCE: 'basicDisobedience',
  NIPPING: 'nipping',
  WALKING_ISSUES: 'walkingIssues',
  HOME_DESTRUCTION: 'homeDestruction',
  JUMPING: 'jumping',
  REACTIVITY: 'reactivity',
  AGGRESSION: 'aggression',
  SLEEP_DISORDERS: 'sleepDisorders',
  DISORIENTATION: 'disorientation',
  HOUSE_SOILING: 'houseSoiling',
  LOW_ENERGY: 'lowEnergy',
}

export const GOALS_OPTIONS_VALUES = {
  BEHAVIOUR_PROBLEMS: 'behaviorProblems',
  NEW_TRICKS: 'newTricks',
  DOG_PSYCHOLOGY: 'dogPsychology',
  HOME_ADAPTATION: 'homeAdaptation',
  SEPARATION_ANXIETY: 'separationAnxiety',
  OTHER: 'other',
  FULLY_HOME_TRAINED: 'fullyHomeTrained',
  RAISE_SAFE_DOG: 'raiseSafeDog',
  IMPROVE_BEHAVIOUR: 'improveBehaviour',
  TRICKS: 'tricks',
  PREVENT_PROBLEMS: 'preventProblems',
  GET_STRONG_BOND: 'getStrongBond',
  EXTEND_QUANTITY: 'extendQuantity',
  MAINTAIN_PRESENT_ROUTINE: 'maintainPresentRoutine',
  LEARN_MORE_ABOUT_DOG_NEEDS: 'learnMoreAboutDogNeeds',
  NEW_WAYS_TIME_SPENDING: 'newWaysTimeSpending',
  ADJUST_LIFE_NEW_HOME: 'adjustLifeInNewHome',
}

export const TIME_OPTIONS_VALUES = {
  FIVE_TEN_MINUTES: 'fiveTenMinutes',
  TEN_FIFTEEN: 'tenFifteenMinutes',
  TEN_TWENTY_MINUTES: 'tenTwentyMinutes',
  FIFTEEN_TWENTY_MINUTES: 'fifteenTwentyMinutes',
  TWENTY_THIRTY_MINUTES: 'twentyThirtyMinutes',
  MORE_THAN_THIRTY: 'moreThanThirty',
}

export const GENERAL_QUESTIONS_OPTIONS_VALUES = {
  NO: 'no',
  SO_SO: 'soSo',
  YES: 'yes',
  NOT_SURE: 'not sure',
}

export const BREED_OPTIONS_VALUES = {
  MIXED_BREED: 'mixedBreed',
  LABRADOR_RETRIEVER: 'labradorRetriever',
  GOLDEN_RETRIEVER: 'goldenRetriever',
  SIBERIAN_HUSKY: 'siberianHusky',
  AMERICAN_PIT_BULL_TERRIER: 'americanPitBullTerrier',
  FRENCH_BULLDOG: 'frenchBulldog',
  GERMAN_SHEPHERD: 'germanShepherd',
  CHIHUAHUA: 'chihuahua',
  YORKSHIRE_TERRIER: 'yorkshireTerrier',
  BORDER_COLLIE: 'borderCollie',
  POODLE: 'poodle',
  BEAGLE: 'beagle',
  SHIH_TZU: 'shihTzu',
  IDK: 'idk',
  OTHER: 'other',
}

export const HEALTH_ISSUES_OPTIONS_VALUES = {
  BUMPS_AND_RASHES: 'bumpsAndRashes',
  RUNNY_EYES_OR_NOSE: 'runnyEyesOrNose',
  SNEEZING_OR_COUGHING: 'sneezingOrCoughing ',
  VOMITING_OR_DIARRHEA: 'vomitingOrDiarrhea',
  SHAKING_HEAD_OR_EARS: 'shakingHeadOrEars',
  NO_ISSUES: 'noIssues',
  VISION: 'vision',
  HEARING: 'hearing',
  JOINTS: 'joints',
  DENTAL: 'dental',
  SKIN: 'skin',
  HEART: 'heart',
  DIGESTION: 'digestion',
  ALLERGIES: 'allergies',
  KIDNEY: 'kidney',
  OBESITY: 'obesity',
  OTHER: 'other',
}

export const DOG_AGE_OPTIONS_VALUES = {
  PUPPY: 'puppy',
  ADOLESCENT: 'adolescent',
  ADULT: 'adult',
  SENIOR: 'senior',
  IDK: 'idk',
}

export const DOG_AGE_STAGE_OPTIONS = {
  YOUNGER_THEN_SIX_MONTH: 'youngerThenSixMonth',
  SIX_TWELVE_MONTHS: 'sixTwelveMonths',
  ONE_TWO_YEAR: 'oneTwoYear',
  TWO_SEVEN_YEARS: 'twoSevenYears',
  MORE_THAN_SEVEN_YEARS: 'moreThanSevenYears',
  IDK: 'idk',
}

export const AGE_ANSWERS_MAP = {
  [DOG_AGE_STAGE_OPTIONS.YOUNGER_THEN_SIX_MONTH]: 'six_months_or_younger',
  [DOG_AGE_STAGE_OPTIONS.SIX_TWELVE_MONTHS]: 'six_to_twelve_months',
  [DOG_AGE_STAGE_OPTIONS.ONE_TWO_YEAR]: 'one_to_two_years',
  [DOG_AGE_STAGE_OPTIONS.TWO_SEVEN_YEARS]: 'two_to_five_years',
  [DOG_AGE_STAGE_OPTIONS.MORE_THAN_SEVEN_YEARS]: 'seven_years_or_older',
}

export const USER_ANSWER_TO_MARKUP_MAP: { [key: string]: string } = {
  [TIME_OPTIONS_VALUES.FIVE_TEN_MINUTES]: 'onboarding.time.fiveTenMinutes',
  [TIME_OPTIONS_VALUES.FIFTEEN_TWENTY_MINUTES]:
    'onboarding.time.fifteenTwentyMinutes',
  [TIME_OPTIONS_VALUES.TWENTY_THIRTY_MINUTES]:
    'onboarding.time.twentyThirtyMinutes',
  [TIME_OPTIONS_VALUES.TEN_TWENTY_MINUTES]: 'onboarding.time.tenTwentyMinutes',
  [TIME_OPTIONS_VALUES.MORE_THAN_THIRTY]: 'onboarding.time.moreThanThirty',
  [GOALS_OPTIONS_VALUES.BEHAVIOUR_PROBLEMS]:
    'onboarding.goals.behaviorProblems',
  [GOALS_OPTIONS_VALUES.DOG_PSYCHOLOGY]: 'onboarding.goals.dogPsychology',
  [GOALS_OPTIONS_VALUES.NEW_TRICKS]: 'onboarding.goals.newTricks',
  [GOALS_OPTIONS_VALUES.OTHER]: 'onboarding.goals.other',
  [GOALS_OPTIONS_VALUES.HOME_ADAPTATION]: 'onboarding.goals.homeAdaptation',
  [GOALS_OPTIONS_VALUES.SEPARATION_ANXIETY]:
    'onboarding.goals.separationAnxiety',
  [GOALS_OPTIONS_VALUES.FULLY_HOME_TRAINED]:
    'onboarding.goals.fullyHomeTrained',
  [GOALS_OPTIONS_VALUES.RAISE_SAFE_DOG]: 'onboarding.goals.raiseSafeDog',
  [GOALS_OPTIONS_VALUES.IMPROVE_BEHAVIOUR]: 'onboarding.goals.improveBehaviour',
  [GOALS_OPTIONS_VALUES.TRICKS]: 'onboarding.goals.tricks',
  [GOALS_OPTIONS_VALUES.PREVENT_PROBLEMS]: 'onboarding.goals.preventProblems',
  [GOALS_OPTIONS_VALUES.GET_STRONG_BOND]: 'onboarding.goals.getStrongBond',
  [GOALS_OPTIONS_VALUES.MAINTAIN_PRESENT_ROUTINE]:
    'onboarding.goals.maintainPresentRoutine',
  [GOALS_OPTIONS_VALUES.LEARN_MORE_ABOUT_DOG_NEEDS]:
    'onboarding.goals.learnMoreAboutDogNeeds',
  [GOALS_OPTIONS_VALUES.NEW_WAYS_TIME_SPENDING]:
    'onboarding.goals.newWaysTimeSpending',
  [GOALS_OPTIONS_VALUES.EXTEND_QUANTITY]: 'onboarding.goals.extendQuantity',
  [GOALS_OPTIONS_VALUES.ADJUST_LIFE_NEW_HOME]:
    'onboarding.goals.adjustLifeInNewHome',
  [CONCERN_OPTIONS_VALUES.CHEWING]: 'onboarding.pointsOfConcern.chewing',
  [CONCERN_OPTIONS_VALUES.BARKING]: 'onboarding.pointsOfConcern.barking',
  [CONCERN_OPTIONS_VALUES.BITING]: 'onboarding.pointsOfConcern.biting',
  [CONCERN_OPTIONS_VALUES.DIGGING]: 'onboarding.pointsOfConcern.digging',
  [CONCERN_OPTIONS_VALUES.FOOD_STEALING]:
    'onboarding.pointsOfConcern.foodStealing',
  [CONCERN_OPTIONS_VALUES.PULING_ON_LEASH]:
    'onboarding.pointsOfConcern.pulling',
  [BEHAVIOR_OPTIONS.LEASH_POOLING]: 'onboarding.behavior.leashPooling',
  [BEHAVIOR_OPTIONS.POTTING_WITHOUT_SCHEDULE]:
    'onboarding.behavior.pottingWithoutSchedule',
  [BEHAVIOR_OPTIONS.CONTROL]: 'onboarding.behavior.control',
  [CONCERN_OPTIONS_VALUES.SEPARATION_ANXIETY]:
    'onboarding.pointsOfConcern.separationAnxiety',
  [CONCERN_OPTIONS_VALUES.POTTY_TRAINING]:
    'onboarding.pointsOfConcern.pottyTraining',
  [CONCERN_OPTIONS_VALUES.SOCIALIZATION]:
    'onboarding.pointsOfConcern.socialization',
  [CONCERN_OPTIONS_VALUES.BASIC_DISOBEDIENCE]:
    'onboarding.pointsOfConcern.basicDisobedience',
  [CONCERN_OPTIONS_VALUES.NIPPING]: 'onboarding.pointsOfConcern.nipping',
  [CONCERN_OPTIONS_VALUES.WALKING_ISSUES]:
    'onboarding.pointsOfConcern.walkingIssues',
  [CONCERN_OPTIONS_VALUES.HOME_DESTRUCTION]:
    'onboarding.pointsOfConcern.homeDestruction',
  [CONCERN_OPTIONS_VALUES.JUMPING]: 'onboarding.pointsOfConcern.jumping',
  [CONCERN_OPTIONS_VALUES.REACTIVITY]: 'onboarding.pointsOfConcern.reactivity',
  [CONCERN_OPTIONS_VALUES.AGGRESSION]: 'onboarding.pointsOfConcern.aggression',
  [CONCERN_OPTIONS_VALUES.SLEEP_DISORDERS]:
    'onboarding.pointsOfConcern.sleepDisorders',
  [CONCERN_OPTIONS_VALUES.DISORIENTATION]:
    'onboarding.pointsOfConcern.disorientation',
  [CONCERN_OPTIONS_VALUES.HOUSE_SOILING]:
    'onboarding.pointsOfConcern.houseSoiling',
  [CONCERN_OPTIONS_VALUES.LOW_ENERGY]: 'onboarding.pointsOfConcern.lowEnergy',
  [COMMANDS_OPTIONS_VALUES.BEG]: 'onboarding.commands.beg',
  [COMMANDS_OPTIONS_VALUES.COME]: 'onboarding.commands.come',
  [COMMANDS_OPTIONS_VALUES.DOWN]: 'onboarding.commands.down',
  [COMMANDS_OPTIONS_VALUES.DROP]: 'onboarding.commands.dropIt',
  [COMMANDS_OPTIONS_VALUES.GIVE]: 'onboarding.commands.give',
  [COMMANDS_OPTIONS_VALUES.PLAY]: 'onboarding.commands.playDead',
  [COMMANDS_OPTIONS_VALUES.ROLL]: 'onboarding.commands.rollOver',
  [COMMANDS_OPTIONS_VALUES.SIT]: 'onboarding.commands.sit',
  [COMMANDS_OPTIONS_VALUES.STAY]: 'onboarding.commands.stay',
  [COMMANDS_OPTIONS_VALUES.SNAKE]: 'onboarding.commands.snake',
  [COMMANDS_OPTIONS_VALUES.TOUCH]: 'onboarding.commands.touch',
  [COMMANDS_OPTIONS_VALUES.FIVE]: 'onboarding.commands.highFive',
  [COMMANDS_OPTIONS_VALUES.KISS]: 'onboarding.commands.kiss',
  [COMMANDS_OPTIONS_VALUES.HUG]: 'onboarding.commands.hug',
  [COMMANDS_OPTIONS_VALUES.TAKE]: 'onboarding.commands.takeIt',
  [COMMANDS_OPTIONS_VALUES.NOSE]: 'onboarding.commands.nose',
  [COMMANDS_OPTIONS_VALUES.NULL]: 'onboarding.commands.null',
  [BREED_OPTIONS_VALUES.MIXED_BREED]: 'onboarding.breed.mixedBreed',
  [BREED_OPTIONS_VALUES.LABRADOR_RETRIEVER]:
    'onboarding.breed.labradorRetriever',
  [BREED_OPTIONS_VALUES.GOLDEN_RETRIEVER]: 'onboarding.breed.goldenRetriever',
  [BREED_OPTIONS_VALUES.SIBERIAN_HUSKY]: 'onboarding.breed.siberianHusky',
  [BREED_OPTIONS_VALUES.AMERICAN_PIT_BULL_TERRIER]:
    'onboarding.breed.americanPitBullTerrier',
  [BREED_OPTIONS_VALUES.FRENCH_BULLDOG]: 'onboarding.breed.frenchBulldog',
  [BREED_OPTIONS_VALUES.GERMAN_SHEPHERD]: 'onboarding.breed.germanShepherd',
  [BREED_OPTIONS_VALUES.CHIHUAHUA]: 'onboarding.breed.chihuahua',
  [BREED_OPTIONS_VALUES.YORKSHIRE_TERRIER]: 'onboarding.breed.yorkshireTerrier',
  [BREED_OPTIONS_VALUES.BORDER_COLLIE]: 'onboarding.breed.borderCollie',
  [BREED_OPTIONS_VALUES.POODLE]: 'onboarding.breed.poodle',
  [BREED_OPTIONS_VALUES.BEAGLE]: 'onboarding.breed.beagle',
  [BREED_OPTIONS_VALUES.SHIH_TZU]: 'onboarding.breed.shihTzu',
  [BREED_OPTIONS_VALUES.IDK]: 'onboarding.breed.idk',
  [BREED_OPTIONS_VALUES.OTHER]: 'onboarding.breed.other',
  [HEALTH_ISSUES_OPTIONS_VALUES.BUMPS_AND_RASHES]:
    'onboarding.healthIssues.bumpsAndRashes',
  [HEALTH_ISSUES_OPTIONS_VALUES.RUNNY_EYES_OR_NOSE]:
    'onboarding.healthIssues.runnyEyesOrNose',
  [HEALTH_ISSUES_OPTIONS_VALUES.SNEEZING_OR_COUGHING]:
    'onboarding.healthIssues.sneezingOrCoughing',
  [HEALTH_ISSUES_OPTIONS_VALUES.VOMITING_OR_DIARRHEA]:
    'onboarding.healthIssues.vomitingOrDiarrhea',
  [HEALTH_ISSUES_OPTIONS_VALUES.SHAKING_HEAD_OR_EARS]:
    'onboarding.healthIssues.shakingHeadOrEars',
  [HEALTH_ISSUES_OPTIONS_VALUES.NO_ISSUES]: 'onboarding.healthIssues.noIssues',
  [HEALTH_ISSUES_OPTIONS_VALUES.VISION]: 'onboarding.healthIssues.vision',
  [HEALTH_ISSUES_OPTIONS_VALUES.HEARING]: 'onboarding.healthIssues.hearing',
  [HEALTH_ISSUES_OPTIONS_VALUES.JOINTS]: 'onboarding.healthIssues.joints',
  [HEALTH_ISSUES_OPTIONS_VALUES.DENTAL]: 'onboarding.healthIssues.dental',
  [HEALTH_ISSUES_OPTIONS_VALUES.SKIN]: 'onboarding.healthIssues.skin',
  [HEALTH_ISSUES_OPTIONS_VALUES.HEART]: 'onboarding.healthIssues.heart',
  [HEALTH_ISSUES_OPTIONS_VALUES.DIGESTION]: 'onboarding.healthIssues.digestion',
  [HEALTH_ISSUES_OPTIONS_VALUES.ALLERGIES]: 'onboarding.healthIssues.allergies',
  [HEALTH_ISSUES_OPTIONS_VALUES.KIDNEY]: 'onboarding.healthIssues.kidney',
  [HEALTH_ISSUES_OPTIONS_VALUES.OBESITY]: 'onboarding.healthIssues.obesity',
  [DOG_AGE_STAGE_OPTIONS.YOUNGER_THEN_SIX_MONTH]:
    'onboarding.age.youngerThenSixMonth',
  [DOG_AGE_STAGE_OPTIONS.SIX_TWELVE_MONTHS]: 'onboarding.age.sixTwelveMonth',
  [DOG_AGE_STAGE_OPTIONS.ONE_TWO_YEAR]: 'onboarding.age.oneTwoYear',
  [DOG_AGE_STAGE_OPTIONS.TWO_SEVEN_YEARS]: 'onboarding.age.twoSevenYears',
  [DOG_AGE_STAGE_OPTIONS.MORE_THAN_SEVEN_YEARS]:
    'onboarding.age.moreThanSevenYears',
  [DOG_AGE_STAGE_OPTIONS.IDK]: 'onboarding.age.idk',
}

export const YOUR_DOG = 'your dog'

export const THEMES = {
  VARIANT_1: 'variant_1',
  VARIANT_2: 'variant_2',
  VARIANT_3: 'variant_3',
  VARIANT_4: 'variant_4',
  VARIANT_5: 'variant_5',
  VARIANT_VC: 'variant_vc',
  VARIANT_S1: 'variant_s1',
  VARIANT_FASHION: 'variant_fashion',
  VARIANT_PT: 'variant_pt',
  VARIANT_HOG: 'variant_hog',
  URGE: 'urge',
}

export const enum CurrentEnvironment {
  DEV = 'dev',
  STAGE = 'stage',
  PROD = 'prod',
}

export const TERMS_OF_USE_LINK = 'https://www.woofz.com/terms-of-use/'
export const PRIVACY_POLICY_LINK = 'https://www.woofz.com/privacy-policy/'
export const PRIVACY_POLICY_LINKS = {
  [CurrentEnvironment.DEV]:
    'https://woofzdotcom.supernove8.com/privacy-policy/',
  [CurrentEnvironment.STAGE]:
    'https://woofzdotcom.supernove8.com/privacy-policy/',
  [CurrentEnvironment.PROD]: 'https://www.woofz.com/privacy-policy/',
}

export const TERMS_OF_USE_LINKS = {
  [CurrentEnvironment.DEV]: 'https://woofzdotcom.supernove8.com/terms-of-use/',
  [CurrentEnvironment.STAGE]:
    'https://woofzdotcom.supernove8.com/terms-of-use/',
  [CurrentEnvironment.PROD]: 'https://www.woofz.com/terms-of-use/',
}

export const ENV_ADMIN_MAP = {
  [CurrentEnvironment.DEV]: 'woofz.supernove8.com',
  [CurrentEnvironment.STAGE]: 'stage-woofz.nove8.com',
  [CurrentEnvironment.PROD]: 'woofz.nove8.com',
}

export const GISMART_BASE_URL_MAP = {
  [CurrentEnvironment.DEV]: 'ws.gidev.xyz',
  [CurrentEnvironment.STAGE]: 'ws.gistage.com',
  [CurrentEnvironment.PROD]: 'woofz.nove8.com',
}

export const ENV_MAP = {
  [CurrentEnvironment.DEV]: 'ws-woofz.supernove8.com',
  [CurrentEnvironment.STAGE]: 'ws-woofz.nove8.com',
  [CurrentEnvironment.PROD]: 'woofz.academy',
}

export const LOCALES = {
  EN: 'en',
  ES: 'es',
  PT: 'pt',
  FR: 'fr',
  DE: 'de',
  IT: 'it',
  KR: 'kr',
  JP: 'jp',
  PL: 'pl',
  TR: 'tr',
  RO: 'ro',
  ZH: 'zh',
}

export const SUPPORTED_LOCALES: string[] = [
  LOCALES.EN,
  LOCALES.ES,
  LOCALES.PT,
  LOCALES.FR,
  LOCALES.DE,
  LOCALES.IT,
  LOCALES.KR,
  LOCALES.JP,
  LOCALES.PL,
  LOCALES.TR,
  LOCALES.RO,
]

export const enum FirebaseRemoteConfigKey {
  WEB_FUNNELS_CONFIG_FILE = 'web_funnels_config_file',
  ABTEST_WOOBE_1258_FIRST_PAGE = 'abtest_woobe_1258_first_page2',
  ABTEST_WOOBE_1191_PUPPIES_PICTURES = 'abtest_woobe_1191_puppies_pictures',
  ABTEST_WOOBE_1192_CHECKOUT_APPLEPLAY = 'abtest_woobe_1192_checkout_applepay',
}

export const enum FirebaseRemoteConfigTestGroup {
  ABTEST_WOOBE_1258_B_NEW_FIRST_PAGE = 'abtest_woobe_1258_b_new_first_page2',
  ABTEST_WOOBE_1191_B_PUPPIES_PICTURES = 'abtest_woobe_1191_b_puppies_pictures',
  ABTEST_WOOBE_1192_B_CHECKOUT_APPLEPLAY = 'abtest_woobe_1192_b_checkout_applepay',
}

export const DEFAULT_WEB_FUNNELS_CONFIG_FILE = {
  signUpMethods: {
    chooseOtherMethodsShown: true,
    otherMethodsShown: {
      facebook: true,
      apple: true,
      google: true,
    },
  },
  webappRedirectForAndroid: false,
  webappRedirectForIOS: false,
  paypalMethodShown: true,
  vcEventLink: '',
  firstScreenBtnText: {
    woofz22: {
      en: 'Get started',
      es: 'Empezar',
      pt: 'Começar',
      fr: 'Commencer',
      de: 'Leg los',
      pl: 'Kontynuuj',
      jp: '続ける',
      kr: '계속',
    },
    woofz22_v2: {
      en: 'Get started',
      es: 'Empezar',
      pt: 'Começar',
      fr: 'Commencer',
      de: 'Leg los',
      pl: 'Kontynuuj',
      jp: '続ける',
      kr: '계속',
    },
    woofz22_v3: {
      en: 'Get started',
      es: 'Empezar',
      pt: 'Começar',
      fr: 'Commencer',
      de: 'Leg los',
      pl: 'Kontynuuj',
      jp: '続ける',
      kr: '계속',
    },
  },
}

export const PDF_BOOK_LINKS = {
  en:
    'https://cdn-prod-unified-content.gismart.xyz/ce453056408feaaf0121c2635933afeb5fcc36b3343ed429f386167d3bb09bbd/OB/Woofz+-+training+plan+PDF.pdf',
  de:
    'https://cdn-prod-unified-content.gismart.xyz/ce453056408feaaf0121c2635933afeb5fcc36b3343ed429f386167d3bb09bbd/OB/Woofz+-+training+plan+DE.pdf',
  pt:
    'https://cdn-prod-unified-content.gismart.xyz/ce453056408feaaf0121c2635933afeb5fcc36b3343ed429f386167d3bb09bbd/OB/Woofz+-+training+plan+PT.pdf',
  es:
    'https://cdn-prod-unified-content.gismart.xyz/ce453056408feaaf0121c2635933afeb5fcc36b3343ed429f386167d3bb09bbd/OB/Woofz+-+training+plan+ES.pdf',
  fr:
    'https://cdn-prod-unified-content.gismart.xyz/ce453056408feaaf0121c2635933afeb5fcc36b3343ed429f386167d3bb09bbd/OB/Woofz+-+training+plan+FR.pdf',
}

export const PDF_FASHION_BOOK_LINK =
  'https://cdn-prod-unified-content.gismart.xyz/ce453056408feaaf0121c2635933afeb5fcc36b3343ed429f386167d3bb09bbd/OB/Stylio+_+Your+Body+Type+%26+Style+Guide.pdf'
export const IDK = 'idk' // idk - i don't know
export const IDK_TRANSLATION_KEY = 'onboarding.age.idk'
export const OTHER_BREED = 'Other Breed'
export const MIXED_BREED = 'Mixed Breed'
export const NONE = 'none'
export const FASHION_COHORT = 'fshn_1'
export const FASHION_COHORTS = ['fshn_1']
export const WOOFZ22_TEST_COHORT = 'woofz22_test'
export const WOOFZ22_V3_COHORT = 'woofz22_v3'
export const WOOFZ23_V3_COHORT = 'woofz23_v3'
export const WOOFZ_PT = 'woofz_pt1'
export const WOOFZ_HOG = 'woofz_hog'
export const WOOFZ_22_V3_D80 = 'woofz22_v3_d80'
export const WOOFZ_22_V3_D70 = 'woofz22_v3_d70'
export const WOOFZ_22_V5 = 'woofz22_v5'
export const WOOFZ_22_V4 = 'woofz22_v4'
export const WOOFZ_22_V6 = 'woofz22_v6'
export const WOOFZ_RT = 'woofz_rt'
export const WOOFZ_OFFER1_70 = 'woofz_offer1_70'
export const WOOFZ_OFFER1_75 = 'woofz_offer1_75'
export const WOOFZ_OFFER1_80 = 'woofz_offer1_80'
export const WOOFZ_OFFER2_70 = 'woofz_offer2_70'
export const WOOFZ_OFFER2_75 = 'woofz_offer2_75'
export const WOOFZ_XMAS_65 = 'woofz_xmas_65'
export const WOOFZ_XMAS_70 = 'woofz_xmas_70'
export const WOOFZ_XMAS_80 = 'woofz_xmas_80'
export const WOOFZ_RFND = 'woofz_rfnd'
export const WOOFZ_RFND2 = 'woofz_rfnd2'
export const WOOFZ22_80 = 'woofz22_80'
export const WOOFZ22_V3_HP1 = 'woofz22_v3_hp1'
export const WOOFZ22_V3_HP2 = 'woofz22_v3_hp2'
export const GOOGLE_UTM = 'googleadwords_int'
export const CONTACT_FORM_LINK_PROD = 'https://account.woofz.com/contact-form'
export const CONTACT_FORM_LINK_DEV =
  'https://account-woofz.gidev.xyz/contact-form'
export const CURRENT_YEAR = 2025
export const SAVED_STATE_COHORTS = [
  'woofz22',
  'woofz22_test',
  'woofz22_v3',
  'woofz22_v2',
  'woofz22_v3_d80',
  'woofz22_v3_d70',
  'fshn_1',
  'woofz_hog',
  'woofz22_v4',
  'woofz_rt',
  'woofz_rfnd',
  'woofz_rfnd2',
]

export const POUNDS_COHORTS = [
  'woofz22_v3',
  'woofz22_v2',
  'woofz22_v3_d80',
  'woofz22_v4',
  'woofz_pt1',
  'woofz22_test',
  'woofz_rt',
]

export const EUR_COHORTS = [
  'woofz22_test',
  'woofz22_v3',
  'woofz22_v4',
  'woofz22_v3_d80',
  'woofz_pt1',
]

export const BRL_COHORTS = ['woofz22_v3', 'woofz22_test']

export const COHORTS_WITH_BREEDS_DROPDOWN = [
  'woofz_pt1',
  'woofz_pf1',
  'woofz_hog',
  'woofz22',
  'woofz22_test',
  'woofz22_v3',
  'woofz22_v2',
  'woofz22_v3_d80',
  'woofz22_v3_d70',
  'woofz22_v3_mp',
  'woofz22_v4',
  'woofz22_v5',
  'woofz_vc1',
  'woofz_vc2',
  'woofz_vc3',
  'woofz_vc4',
  'woofz_vc5',
  'woofz_vc6',
  'woofz_rt',
  'woofz_rfnd',
  'woofz_rfnd2',
]

export const WEB_APP_API_KEYS = {
  en: 'f57160ad-a043-426f-a314-8fbb20570222',
  es: '9677a0f0-98cf-4a86-9a98-b6a1755ebb41',
  pt: 'ea121635-77f1-46cd-8dac-4136cbca0b54',
  fr: '08e2f831-3862-4576-b3dd-9f1bc7af072b',
  de: '7bfb0fcf-c065-4d28-b098-52514a6a0a91',
  it: 'c53130db-5d38-4577-b517-227294108574',
  pl: '5e10b0a6-c060-430c-9bfe-afe0e4a4fd02',
  jp: 'd4066fa1-1398-4d81-991d-880388ccf915',
  kr: '2d88f7b9-42d5-4e79-943b-67946833b1ed',
}

export const WEBAPP_LINKS = {
  dev: 'https://app.supernove8.com',
  stage: 'https://app.supernove8.com',
  prod: 'https://app.woofz.com',
}

export const enum FLOW {
  WEB = 'web',
  W2W = 'w2w',
}

export const WEB_APP_REDIRECT_URL = {
  dev: 'https://app.supernove8.com',
  stage: 'https://app.supernove8.com',
  prod: 'https://app.woofz.com',
}

export const enum Countries {
  GB = 'GB',
  BR = 'BR',
  CA = 'CA',
  AU = 'AU',
  IT = 'IT',
  DE = 'DE',
  ES = 'ES',
  FR = 'FR',
  US = 'US',
}

export const vcInitialStatus: IVcUserStatus = {
  hasEmail: false,
  email: '',
  onboarding: {} as TAnswers,
  hasSubscription: false,
}
